import { Header } from '@axo/deprecated/util/ui-components';
import { API_URL } from '@axo/insurance/const/fi';
import {
  AuthProvider,
  getInsuranceSourceTagFromSearchParams,
  WebSocketProvider,
} from '@axo/insurance/feature/providers';
import {
  getInitialAppContextState,
  getInitialDataAccessContextState,
  getInitialInsuranceContextState,
  InsuranceProvider,
  OnEnd,
} from '@axo/insurance/feature/providers/fi';
import { EventLogProvider } from '@axo/shared/data-access/event-log';
import {
  DataAccessContext,
  DataAccessProvider,
} from '@axo/shared/data-access/provider';
import { LoanApplicationProvider } from '@axo/shared/feature/providers';
import { useContext } from 'react';
import { QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet } from 'react-router-dom';
import { Error as ErrorPage } from '../../../components/Error';
import { insuranceSourceTag } from '../../../consts/sourceTag';
import { LoggingErrorBoundary } from './LoggingErrorBoundary';
import styles from './Main.module.scss';

const queryClient = new QueryClient();

type MainProps = {
  onEnd: OnEnd;
  onError: (error?: Error) => void;
};
export function Main({ onEnd, onError }: MainProps) {
  const search = new URLSearchParams(window.location.search);
  const source = getInsuranceSourceTagFromSearchParams();

  const { state: dataAccessContext } = useContext(DataAccessContext);

  return (
    <DataAccessProvider
      queryClient={queryClient}
      url={{
        api: import.meta.env.VITE_APP_API_URL,
        // ws: import.meta.env.VITE_APP_WS_URL,
      }}
      storedInitialState={getInitialDataAccessContextState(search)}
    >
      <LoanApplicationProvider initialState={getInitialAppContextState(search)}>
        <ErrorPage>
          <AuthProvider onError={onError}>
            <EventLogProvider
              apiURL={API_URL}
              token={dataAccessContext.user.token}
              marketCountry="FI"
              source="insurance"
            >
              <LoggingErrorBoundary>
                <WebSocketProvider>
                  <InsuranceProvider
                    onEnd={onEnd}
                    sourceTag={source ?? insuranceSourceTag}
                    initialState={getInitialInsuranceContextState(search)}
                  >
                    <div className={styles.page}>
                      <Header
                        country="fi"
                        header_links={[]}
                        focusMode={false}
                      />
                      <div className={styles.container}>
                        <Outlet />
                      </div>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </div>
                  </InsuranceProvider>
                </WebSocketProvider>
              </LoggingErrorBoundary>
            </EventLogProvider>
          </AuthProvider>
        </ErrorPage>
      </LoanApplicationProvider>
    </DataAccessProvider>
  );
}
